import React from 'react'
import * as moment from 'moment'
import TalkInfo from './TalkInfo'
import { Link } from 'gatsby'
import { Image } from '../Image'

export default function Talk({ talk }) {
  const {
    frontmatter: {
      title,
      date,
      slide,
      video,
      organizerLogo,
      location,
      info,
      description,
    },
    fields: { slug },
  } = talk
  const featureImage = slug.substring(0, slug.length - 1)
  const isUpcoming = moment(date).isAfter(moment())

  return (
    <div className="talk-container">
      {isUpcoming && (
        <div className="card-ribbon">
          <span>Upcoming</span>
        </div>
      )}
      <div
        className="talk-header"
        style={{ paddingRight: isUpcoming ? "3rem" : "1rem" }}
      >
        <Image
          alt=""
          className="talk-logo"
          src={`/img/speaking/logos/logo-${organizerLogo}`}
          options="format=auto,width=64"
          loading="lazy"
        />
        <div>
          <h2 className="talk-title">
            <Link to={`/talks/${slug}`}>{title}</Link>
          </h2>
          <div className="talk-location">
            {location}, {moment(date).format('D MMM YYYY')}
          </div>
        </div>
      </div>
      <Image
        className="talk-image"
        src={`/img/speaking/${featureImage}-00.jpg`}
        options="format=auto,width=500"
        loading="lazy"
        alt={title}
      />
      <p className="talk-description">{description}</p>
      <div style={{ padding: '0 1rem' }}>
        <TalkInfo video={video} slide={slide} info={info} />
      </div>
    </div>
  )
}
